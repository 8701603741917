const SignUpAndReserve = ({ signedUp }) => {
    return (
        <div className="max-w-[1000px] mx-auto text-xl leading-tight transition-all">
            <div className={
                (signedUp) ? "hidden" : "text-center"
            }>
                <h1 className="text-2xl leading-tight pb-3">
                    Tidbyt Gen 2 is launching soon on Kickstarter and shipping next spring!
                </h1>

                <p>
                    Sign up and we'll let you know the moment it's live.
                    Plus we'll let you know how to get $50 off.
                </p>

                <div className="klaviyo-form-XfH7hW max-w-[600px] mx-auto"></div>
            </div>

            <div className={
                (signedUp) ? "space-y-3 pb-4 max-w-[800px]" : "hidden"
            }>
                <h1 className="text-3xl leading-tight text-center">
                    Want to save $50 on Tidbyt&nbsp;Gen&nbsp;2?
                </h1>

                <p>
                    We've got a pretty sweet deal if you'd like to reserve
                    your new Tidbyt now. Here's how it works:
                </p>

                <ol className="list-['✅'] ml-4 py-4 space-y-4">
                    <li className="pl-2">
                        <p className="font-bold">Put down $1 now to reserve your Gen 2.</p>
                        This $1 will go toward production of your Tidbyt.
                    </li>
                    <li className="pl-2">
                        <p className="font-bold">Lock in our limited discount pricing.</p>
                        When Tidbyt Gen 2 ships, we'll give you the lowest price
                        available&nbsp;— $149 + shipping.
                    </li>
                </ol>
                <p>
                    That's $50 off what we expect Tidbyt Gen 2 to cost in stores.
                    Pretty good deal for a dollar, eh?
                </p>

                <div className="flex items-center justify-center py-2">
                    <a href="https://tidbyt.com/cart/45545864069353:1?channel=buy_button">
                        <button className="btn border-0 text-neutral-50 bg-indigo-500 hover:bg-[rgb(241,156,250)]">Reserve Gen 2 for $1</button>
                    </a>
                </div>

                <p className="text-xs">
                    By making a reservation, you're committing to purchasing Tidbyt&nbsp;Gen&nbsp;2
                    when we launch on Kickstarter. We'll let you know when we're live so that you
                    can claim your discount.
                </p>
            </div>
        </div>
    );
}

export default SignUpAndReserve