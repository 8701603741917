import React from "react";
import Meta from "../components/Meta";
import Navbar from "./../components/Navbar";
import "./../styles/global.css";
import "./../util/analytics";
import { Route, Router, Switch } from "./../util/router";
import NotFoundPage from "./404";
import AboutPage from "./about";
import IndexPage from "./index";
import LegalPage from "./legal";
import PricingPage from "./pricing";
import { Redirect } from "react-router-dom";

function App(props) {
  return (
    <Router>
      <>
        <Meta />

        <Navbar />


        <Switch>
          <Route exact path="/" component={IndexPage} />

          <Route exact path="/reserve" children={<Redirect to="/#reserve" />} />

          <Route exact path="/about" component={AboutPage} />

          <Route exact path="/pricing" component={PricingPage} />

          <Route exact path="/legal/:section" component={LegalPage} />

          <Route component={NotFoundPage} />
        </Switch>
      </>
    </Router>
  );
}

export default App;
