import React, { useEffect, useState } from "react";

import "@google/model-viewer/dist/model-viewer";

import screenVideo from "../assets/citibike.mp4";
import poster from "../assets/poster.webp";
import t2 from "../assets/t2.glb";

import nyanVideo from "../assets/nyan.webm";
import nyan from "../assets/nyan.webp";
import tapVideo from "../assets/tap.mp4";
import tapVideo2 from "../assets/tap2.mp4";
import tapVideo2Poster from "../assets/tap2-poster.jpg";

import beepBoopVideo from "../assets/beepboop.mp4";
import kickstandVideo from "../assets/kickstand.mp4";
import devTools from "../assets/t2dev.webp";
import wallMountVideo from "../assets/wall-mount.mp4";

import { shuffle } from "lodash";
import Marquee from "react-fast-marquee";
import { ReactComponent as PixelDotsClipPath } from "../assets/dots.svg";
import AppPreview from "../components/AppPreview";

import "yet-another-react-lightbox/styles.css";
import Gallery from "../components/Gallery";
import SignUpAndReserve from "../components/SignUp";

const INITIAL_CAMERA_ORBIT = "63.71deg 78.58deg 4.311m";
const INITIAL_FIELD_OF_VIEW = "43.96deg";

const FEATURED_APPS = shuffle([
  "strava",
  "arcade-classics",
  "nyc-subway",
  "youtube",
  "twitch",
  "gcal",
  "baseball",
  "football",
  "basketball",
  "yahoofantasynfl",
  "spotify",
  "weather",
  "world-clock",
  "stocks",
  "day-night-map",
  "air-quality",
  "traffic",
  "steam",
  "fuzzy-clock",
  "fido"
]);

function isSafari() {
  const navigator = window.navigator;
  const ua = navigator.userAgent.toLowerCase();
  return ((ua.indexOf('safari') !== -1) && (!(ua.indexOf('chrome') !== -1) && (ua.indexOf('version/') !== -1)));
}

async function loadTexture() {
  await customElements.whenDefined("model-viewer");

  const mv = document.querySelector("model-viewer");
  if (!mv.createCanvasTexture) {
    // probably model-viewer didn't work for some reason (ancient browser)
    return;
  }

  const texture = mv.createCanvasTexture();
  const canvas = texture.source.element;
  const ctx = canvas.getContext('2d');

  // Create our video element
  let video = document.createElement('video');
  video.src = screenVideo;
  video.autoplay = true;
  video.loop = true;
  video.muted = true;
  video.playsInline = true;
  video.play();

  // When it loads
  video.addEventListener('loadedmetadata', () => {
    const drawScaled = function (image, x, y, scale) {
      ctx.setTransform(-scale, 0, 0, -scale, x, y); // sets scale and origin

      const width = image.width || image.videoWidth;
      const height = image.height || image.videoHeight;
      ctx.drawImage(image, -width / 2, -height / 2);
    };

    const drawFrame = () => {
      drawScaled(video, canvas.width / 2, canvas.height / 2, 0.96 * canvas.width / video.videoWidth);
      texture.source.update();
      requestAnimationFrame(drawFrame);
    };

    mv.addEventListener("load", () => {
      const { pbrMetallicRoughness } = mv.model.getMaterialByName("Diffuser");
      pbrMetallicRoughness.baseColorTexture.setTexture(texture);
      pbrMetallicRoughness.setRoughnessFactor(0.6);
      pbrMetallicRoughness.setMetallicFactor(0.4);
      pbrMetallicRoughness.setBaseColorFactor('#cdcdcd');
      drawFrame();
    });
  });

  // handle AR
  mv.addEventListener("ar-status", (e) => {
    if (e.detail.status === "session-started") {
      // Enable panning when AR is presenting
      mv.removeAttribute("disable-pan");
    } else {
      // disable panning when AR is not presenting
      mv.setAttribute("disable-pan", true);
    }
  });


}

function IndexPage(props) {
  const [signedUp, setSignedUp] = useState(false);
  const [showReserveAtTop, setShowReserveAtTop] = useState(false);

  useEffect(() => loadTexture(), []);

  useEffect(() => {
    window.addEventListener("klaviyoForms", (e) => {
      if (e.detail.type === "submit") {
        setSignedUp(true);
      }
    });
  }, []);

  useEffect(() => {
    const handleReserve = () => {
      if (window.location.hash === "#reserve") {
        setSignedUp(true);
        setShowReserveAtTop(true);
      }
    };

    handleReserve();
    window.addEventListener("hashchange", handleReserve);
  });


  return (
    <>
      <div className="prose">
        {showReserveAtTop && (
          <section id="get-gen2" className="px-8 pt-8 pb-4 flex w-full bg-[rgb(249,210,69)] scroll-mt-[10vh]">
            <SignUpAndReserve signedUp={true} />
          </section>
        )}

        <section className="px-8 py-8 w-full bg-[rgb(46,46,46)] space-y-3 relative">
          <video
            muted autoPlay loop playsInline disableRemotePlayback
            src={tapVideo2}
            poster={tapVideo2Poster}
            className="hover:brightness-105 mx-auto rounded-lg lg:w-[896px]"
            onContextMenu={e => e.preventDefault()}
          />
          <h1 className="max-w-[1000px] mx-auto text-center text-2xl leading-tight text-white">
            Tidbyt Gen 2. The best display we've ever built.
          </h1>
        </section>

        {!showReserveAtTop && (
          <section id="get-gen2" className="px-8 pt-8 pb-4 flex w-full bg-[rgb(249,210,69)] scroll-mt-[10vh]">
            <SignUpAndReserve signedUp={signedUp} />
          </section>
        )}

        <section className="px-16 py-8 flex w-full overflow-clip bg-sky-300">
          <div className="max-w-[1000px] mx-auto text-xl leading-tight space-y-3">
            <h1 className="text-4xl leading-tight">
              Look to Tidbyt.
            </h1>

            {!isSafari() &&
              <video
                src={nyanVideo} muted autoPlay loop playsInline disableRemotePlayback
                className="float-right relative mr-[-60%] sm:mr-[-50%] md:mr-[-30%] lg:mr-[-10%] sm:max-w-[500px] hover:brightness-125"
                style={{
                  shapeOutside: `url(${nyan})`,
                  shapeMargin: "40px",
                  transform: "scaleX(-1) rotate(60deg)",
                }}
                onMouseEnter={e => e.target.playbackRate = 2}
                onMouseLeave={e => e.target.playbackRate = 1}
                onContextMenu={e => e.preventDefault()}
              />
            }

            <p>
              Want to see what's playing on your Spotify? Or what's
              next on your calendar?
            </p>

            <p>
              Want to follow your favorite team,
              or just show off photos of your favorite person?
            </p>
            <p>
              With hundreds of fun apps, Tidbyt can show you all that and more.
            </p>

            <h1 className="pt-8 text-4xl leading-tight">
              Sharper, crisper, clearer.
            </h1>
            <p>
              Tidbyt's gorgeous new display brings you sharper text
              and clearer photos. With Gen&nbsp;2, you can see
              things clearly whether you're up close or all the way across the room.
            </p>
          </div>
        </section>

        <section className="px-16 pb-8 flex w-full overflow-clip bg-sky-300">
          <Gallery />
        </section>

        <section className="pb-8 bg-[rgb(249,210,69)]">
          <div className="h-[60vw] md:h-[400px]">
            <model-viewer
              src={t2}
              camera-controls disable-pan disable-zoom
              ar ar-modes="webxr scene-viewer quick-look"
              poster={poster}
              orbit-sensitivity="0.2"
              camera-orbit={INITIAL_CAMERA_ORBIT}
              field-of-view={INITIAL_FIELD_OF_VIEW}
              min-field-of-view="30deg"
              max-field-of-view="30deg"
              interaction-prompt="auto"
            >
              <div className="progress-bar hide" slot="progress-bar">
                <div className="update-bar"></div>
              </div>
            </model-viewer>
          </div>
        </section>

        <section className="px-16 pb-8 flex w-full bg-[rgb(46,46,46)] text-white">
          <div className="max-w-[1000px] mx-auto text-xl leading-tight space-y-3">
            <h1 className="pt-8 text-4xl leading-tight">
              Tap for more.
            </h1>
            <p>
              Skip ahead, go back, or interact. Our new touch pad puts you in full control. Customize and program it for exactly what you need.
            </p>
          </div>
        </section>

        <section className="px-8 md:px-16 bg-[rgb(46,46,46)]">
          <video
            src={tapVideo} muted autoPlay loop playsInline disableRemotePlayback
            className="hover:brightness-105 mx-auto rounded-lg lg:w-[896px]"
            onContextMenu={e => e.preventDefault()}
          />
        </section>

        <section className="px-16 py-8 flex w-full bg-[rgb(46,46,46)] text-white">
          <div className="max-w-[1000px] mx-auto text-xl leading-tight space-y-3">
            <h1 className="text-4xl leading-tight">
              Perfect anywhere.
            </h1>

            <p>
              Tidbyt Gen 2 fits perfectly on your desk, your bookshelf, or on your wall.
              <b> The kickstand props it up for the perfect viewing angle</b> when it's on your desk.
            </p>

            <div className="py-8 flex items-center flex-col sm:flex-row gap-x-8 gap-y-8 w-full">
              <video
                src={kickstandVideo} muted autoPlay loop playsInline disableRemotePlayback
                className="shrink hover:brightness-105 rounded-lg sm:w-[48%]"
                onContextMenu={e => e.preventDefault()}
              />
              <video
                src={wallMountVideo} muted autoPlay loop playsInline disableRemotePlayback
                className="shrink hover:brightness-105 rounded-lg sm:w-[48%]"
                onContextMenu={e => e.preventDefault()}
              />
            </div>

            <p>
              If you want to hang Tidbyt on your wall, <b>the built-in mount gets it perfectly flush. </b>
              Just look around and you probably already know where your Tidbyt will go.
            </p>

            <h1 className="pt-4 text-4xl leading-tight">
              Tune in with audio.
            </h1>
            <p>
              To keep things fun, we've added a 16-bit mono speaker!
            </p>
            <p>
              Definitely not hi-fi stereo,
              but it excels at beeps, boops and 8-bit tunes that will grab your attention. Use it
              for setting an alarm, getting notifications &amp; alerts, or just playing a little tune.
            </p>
          </div>
        </section>

        <section className="pb-8 px-8 md:px-16 bg-[rgb(46,46,46)]">
          <video
            src={beepBoopVideo} muted autoPlay loop playsInline disableRemotePlayback
            className="hover:brightness-105 mx-auto rounded-lg lg:w-[896px]"
            onContextMenu={e => e.preventDefault()}
          />
        </section>

        <section className="px-16 py-8 flex w-full bg-sky-300">
          <div className="max-w-[1000px] mx-auto text-xl leading-tight space-y-3">
            <h1 className="text-4xl leading-tight">
              Powered by the people.
            </h1>

            <p>
              Since we launched the original Tidbyt in 2021, we've been blown away
              by the community of people who've built hundreds of apps for it.
            </p>
            <p>
              For work, for play, for fun, for art, for everything. And the best part
              is, we're bringing all of those apps to Tidbyt Gen 2.
            </p>
          </div >
        </section>

        <section className="w-full bg-sky-300 space-y-3">
          <Marquee direction="left" speed={25}>
            {FEATURED_APPS.slice(0, FEATURED_APPS.length / 2).map((id) =>
              <div key={id} className="max-w-[300px] mx-2 rounded-lg overflow-clip">
                <AppPreview appID={id} />
              </div>
            )}
          </Marquee>
          <Marquee direction="right" speed={25}>
            {FEATURED_APPS.slice(FEATURED_APPS.length / 2).map((id) =>
              <div key={id} className="max-w-[300px] mx-2 rounded-lg overflow-clip">
                <AppPreview appID={id} />
              </div>
            )}
          </Marquee>
        </section>

        <section className="px-16 py-8 flex w-full bg-sky-300">
          <div className="max-w-[1000px] mx-auto text-xl leading-tight space-y-3">
            <h1 className="text-4xl leading-tight">
              Build your own.
            </h1>
            <p>
              Whether you're a grizzled veteran or just learning how to code, you can build
              your own apps for Tidbyt. We've got a full-featured development kit,
              a simulator, and plenty of examples to get you started.
            </p>

            <img src={devTools} alt="Screenshot of Tidbyt app development" />

            <p>
              Hack together something for yourself just for fun. Build something
              for work and share it with your team. Or build something for the whole world
              and share it with the community.
            </p>
          </div >
        </section >

        <section className="px-8 pt-8 pb-4 flex w-full bg-[rgb(255,209,12)] scroll-mt-[10vh]">
          <SignUpAndReserve signedUp={signedUp} />
        </section>

        <PixelDotsClipPath />

        <section className="px-16 pb-8 flex w-full bg-[rgb(46,46,46)] text-white">
          <div className="max-w-[1000px] mx-auto text-xl leading-tight space-y-3">
            <h1 className="pt-8 text-4xl leading-tight">
              Got questions? We have answers.
            </h1>

            <FAQItem question="What's the difference between Gen 1 and Gen 2?" answer={
              <>
                <p>
                  Tidbyt Gen 2 is a complete redesign of the original Tidbyt.
                  It has a new display, a new touch pad, a new speaker, and a whole new look.
                </p>
                <p>
                  It can still run all of the same great apps as the original Tidbyt.
                </p>
              </>
            } />
            <FAQItem question="When can I get my Tidbyt Gen 2?" answer={
              <>
                <p>
                  Tidbyt Gen 2 will be launching on Kickstarter in October, with a preliminary
                  shipping date of spring 2024.
                </p>
              </>
            } />
            <FAQItem question="What's Kickstarter? How does that work?" answer={
              <>
                <p>
                  Kickstarter is a place for launching all sorts of amazing new products.
                  It's where we launched the original Tidbyt, and it's where we'll be
                  launching Tidbyt Gen 2.
                </p>
                <p>
                  When you back a project on Kickstarter, you're helping to fund the
                  development of a new product. In return, you get to be one of the first
                  people to get your hands on it — and usually at a pretty big discount.
                </p>
              </>
            } />
            <FAQItem question="How much will Tidbyt Gen 2 cost?" answer={
              <>
                <p>
                  We're aiming for $199 at retail. But if you sign up and reserve one now,
                  we'll lock you in for a special early bird price of $149.
                </p>
              </>
            } />
            <FAQItem question="How does it actually work?" answer={
              <>
                <p>
                  Tidbyt connects to your Wi-Fi network and displays information from the internet.
                </p>
                <p>
                  Use your phone to set it up and pick your favorite apps, and then you can control it from anywhere.
                </p>
              </>
            } />
            <FAQItem question="Can I make my own apps for Tidbyt Gen 2?" answer={
              <>
                <p>
                  Absolutely! You can develop your own apps for Tidbyt Gen 2.
                </p>
                <p>
                  We provide a full-featured development kit and a simulator to help you get started.
                  You can build apps using our API, and even share your creations with the Tidbyt community.
                </p>
              </>
            } />

          </div>
        </section>
      </div>
    </>
  );
}

const FAQItem = ({ question, answer }) => (
  <div tabIndex={0} className="collapse collapse-plus border focus:border-0 focus:bg-[rgb(255,209,12)] focus:text-black">
    <div className="collapse-title text-xl font-semibold">
      {question}
    </div>
    <div className="collapse-content">
      <div className="pb-4 space-y-3 pr-[10%]">
        {answer}
      </div>
    </div>
  </div>
);

export default IndexPage;
