import React from "react";
import { Link } from "./../util/router";

import logo from "../assets/Tidbyt_Logo_Color.png";

function Navbar(props) {
  return (
    <header className="py-5 px-4 bg-zinc-800 border-b border-solid border-zinc-600 sticky top-0 z-10">
      <div className="container flex flex-row flex-wrap items-center mx-auto justify-between gap-x-[20vw]">
        <Link to="/" className="flex-1 max-w-[300px]">
          <img src={logo} alt="Tidbyt Logo" />
        </Link>
        <button
          className="btn btn-primary"
          onClick={() => {
            const section = document.querySelector("#get-gen2");
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          Get Tidbyt Gen 2
        </button>
      </div>
    </header >
  );
}

export default Navbar;
