import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";

const Gallery = () => {
    const photos = [
        {
            src: require("../assets/gallery/t2logo.webp"),
        },
        {
            src: require("../assets/gallery/t2mlb2.webp"),
        },
        {
            src: require("../assets/gallery/t2sunrise-fixed.webp"),
        },
        {
            src: require("../assets/gallery/t2spotify-fixed.webp"),
        },
    ];

    const [index, setIndex] = useState(-1);

    return <>
        <div className="grid grid-cols-2 grid-rows-2 gap-4">
            {photos.map((photo, i) => (
                <div
                    key={i}
                    onClick={() => setIndex(i)}
                    className="rounded-lg overflow-clip hover:brightness-110 cursor-pointer"
                >
                    <img
                        loading="lazy" alt={photo.caption}
                        className="w-full h-full object-cover"
                        src={photo.src}
                    />
                </div>
            ))}
        </div>
        <Lightbox
            slides={photos}
            index={index}
            open={index > -1}
            close={() => setIndex(-1)}
        />
    </>;
};

export default Gallery;