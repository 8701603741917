const AppPreview = ({ appID }) => {
    return (
        <div className="bg-black">
            <img
                loading="lazy" alt={`${appID} preview`}
                className="bg-black block aspect-[2/1] ir-pixelated w-[33vw]"
                style={{ clipPath: "url(#dots)" }}
                src={`//prod.tidbyt.com/app-server/preview/${appID}.gif`}
                onError={(e) => (e.target.style.display = 'none')}
            />
        </div>
    );
}

export default AppPreview;